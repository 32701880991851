import React from 'react';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

interface IFullWidthBannerProps {
  children?: React.ReactNode;
  className?: string;
  contentClassName?: string;
  image?: React.ReactNode;
  imageStyle?: React.CSSProperties;
  legal?: React.ReactNode;
  style?: React.CSSProperties;
}

export const FullWidthBanner: React.FC<IFullWidthBannerProps> = ({
  children,
  legal,
  image,
  className,
  style,
  imageStyle,
  contentClassName,
}) => {
  return (
    <div className={cx('banner', className)} style={style}>
      <div className={cx('banner__container')}>
        <div className={cx('banner__content', contentClassName)}>
          {children}
          <div className={cx('banner__legal')}>{legal}</div>
        </div>
        <div className={cx('banner__image')} style={imageStyle}>
          {image}
        </div>
      </div>
    </div>
  );
};
