import { SearchStoreType } from 'pages/MainPage/blocks/HelpBlock/types';

import {
  BREAKPOINT_375,
  BREAKPOINT_480,
  BREAKPOINT_481,
  BREAKPOINT_800,
  BREAKPOINT_960,
  BREAKPOINT_1024,
  BREAKPOINT_1025,
  BREAKPOINT_1100,
  BREAKPOINT_1280,
  BREAKPOINT_1440,
  BREAKPOINT_1680,
  BREAKPOINT_1920,
  BREAKPOINT_MAX,
} from './utils/constants';

/** Енам входного параметра для блоков тарифов
 * используем для параметров запроса на сервер */
export enum ETariffBlockType {
  forYou,
  forHome,
  forFamily,
  forDevices,
}

export enum SectionType {
  AppSection = 'app_section',
  MainSlider = 'main_carousel',
  ForBusiness = 'for_business',
  ForDevices = 'for_devices',
  ForFamily = 'for_family',
  ForHome = 'for_home',
  ForYou = 'for_you',
  HRSection = 'hr_section',
  ServiceSection = 'service_section',
  HelpSection = 'block_help_2024',
}

export interface ISection {
  content: Record<string, any>;
  data: Record<string, any>;
  full_width: boolean;
  section: SectionType;
  sorting: number;
}
export enum BreakpointEnum {
  MAX = BREAKPOINT_MAX,
  DESKTOP_1920 = BREAKPOINT_1920,
  DESKTOP_1680 = BREAKPOINT_1680,
  DESKTOP_1440 = BREAKPOINT_1440,
  DESKTOP_1280 = BREAKPOINT_1280,
  DESKTOP_1100 = BREAKPOINT_1100,
  DESKTOP_1025 = BREAKPOINT_1025,
  TABLET_1024 = BREAKPOINT_1024,
  TABLET_960 = BREAKPOINT_960,
  MOBILE_480 = BREAKPOINT_480,
  TABLET_481 = BREAKPOINT_481,
  TABLET_800 = BREAKPOINT_800,
  MOBILE_375 = BREAKPOINT_375,
}
export type ISectionParsed = {
  [key in SectionType]: ISection;
};
export interface RootState {
  breakpoint: BreakpointEnum;
  helpSlice: SearchStoreType;
  sectionsFixed: ISection[];
  sectionsParsed: ISectionParsed;
}

export interface MainPageProps {
  sectionsFiltered: ISection[];
}

export type WithRenderFlagComponent<T> = T & {
  shouldRender: boolean;
};
