import React from 'react';
import classNames from 'classnames/bind';
import { Button, Text } from '@carnica/ui-kit';

import { Metric } from 'pages/MainPage/metric';
import { MenuUrlTarget } from 'pages/MainPage/utils/utils';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export interface ContentBlockProps {
  backgroundImage: string;
  buttonLink: string;
  buttonText: string;
  description: string;
  isBlankUrlTarget: boolean;
  logoImage: string;
  title: string;
}

export const ContentBlock: React.FC<ContentBlockProps> = ({
  backgroundImage,
  logoImage,
  title,
  description,
  buttonText,
  buttonLink,
  isBlankUrlTarget,
}) => {
  return (
    <div
      className={cx('wrapper')}
      style={{
        backgroundImage: `url(${backgroundImage})`,
      }}
    >
      <img alt="" height="52" src={logoImage} width="52" />
      <Text className={cx('title')} variant="display-small">
        {title}
      </Text>
      <Text color="secondary" variant="body-small">
        {description}
      </Text>
      <Metric
        asChild
        data={JSON.stringify({ blockTitle: title, buttonText })}
        events={{ commonMetric: ['handleButtonClick'] }}
        mode="click"
      >
        <Button
          className={cx('button')}
          href={buttonLink}
          size="m"
          tagName="a"
          target={MenuUrlTarget.get(isBlankUrlTarget)}
        >
          {buttonText}
        </Button>
      </Metric>
    </div>
  );
};
