import { useEffect, useState } from 'react';

import { fetchData } from 'pages/MainPage/blocks/TariffCardBlock/api';
import { ITariffCardBlockProps } from 'pages/MainPage/blocks/TariffCardBlock/types';
import { ETariffBlockType } from 'pages/MainPage/types';

/** Хук получения данных тарифов */
export const usePrepareTariffCardBlock = (blockType: ETariffBlockType) => {
  const [result, setResult] = useState<ITariffCardBlockProps | null>(null);
  const params = { blockType };

  useEffect(() => {
    const controller = new AbortController();
    fetchData({ params, signal: controller.signal })
      .then(({ data }) => {
        setResult(data ?? null);
      })
      .catch(() => setResult(null));

    return () => controller.abort();
  }, []);

  return result;
};
