import React, { FC } from 'react';
import { Button, Text } from '@carnica/ui-kit';
import { compose } from '@beef/redux';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';
import { setChatWidgetOpen } from '@beef/widget-chat';

import { RootState } from 'pages/MainPage/types';
import {
  selectChatButtonHelp,
  selectChatTitleHelp,
} from 'pages/MainPage/store/slices/help/selectors';
import { Metric } from 'pages/MainPage/metric';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const _FooterHelpBlock: React.FC<{ btnText?: string; title?: string }> = ({ title, btnText }) => {
  return (
    <div className={cx('help-footer')}>
      <Text className={cx('help-footer__title')}>{title}</Text>
      <Metric
        asChild
        data={btnText}
        events={{ helpBlockMetric: ['handleChatButtonClick'] }}
        mode="click"
      >
        <Button onClick={() => setChatWidgetOpen(true)} size="m">
          {btnText}
        </Button>
      </Metric>
    </div>
  );
};

export const FooterHelpBlock = compose<FC>(
  connect((state: RootState) => ({
    title: selectChatTitleHelp(state),
    btnText: selectChatButtonHelp(state),
  })),
)(_FooterHelpBlock);
