import React from 'react';
import classNames from 'classnames/bind';

import { Metric } from 'pages/MainPage/metric';

import { AppStoresLinks } from '../../types';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const StoresLinks: React.FC<{
  blockTitle?: string;
  links: AppStoresLinks;
}> = ({ links, blockTitle }) => {
  const { appStore, huaweiStore, ruStore, googleStore } = links;
  return (
    <div className={cx('stores-links')}>
      <Metric
        asChild
        data={JSON.stringify({ iconName: 'app store', blockTitle })}
        events={{ commonMetric: ['handleIconClick'] }}
        mode="click"
      >
        <a
          aria-label="Apple Store"
          className={cx('icon-wrap')}
          href={appStore.href}
          rel="noreferrer"
          target="_blank"
        >
          <img alt="" src={appStore.imageUrl} />
        </a>
      </Metric>
      <Metric
        asChild
        data={JSON.stringify({ iconName: 'google store', blockTitle })}
        events={{ commonMetric: ['handleIconClick'] }}
        mode="click"
      >
        <a
          aria-label="Google Play"
          className={cx('icon-wrap')}
          href={googleStore.href}
          rel="noreferrer"
          target="_blank"
        >
          <img alt="" src={googleStore.imageUrl} />
        </a>
      </Metric>
      <Metric
        asChild
        data={JSON.stringify({ iconName: 'huawei store', blockTitle })}
        events={{ commonMetric: ['handleIconClick'] }}
        mode="click"
      >
        <a
          aria-label="Huawei Store"
          className={cx('icon-wrap')}
          href={huaweiStore.href}
          rel="noreferrer"
          target="_blank"
        >
          <img alt="" src={huaweiStore.imageUrl} />
        </a>
      </Metric>
      <Metric
        asChild
        data={JSON.stringify({ iconName: 'ru store', blockTitle })}
        events={{ commonMetric: ['handleIconClick'] }}
        mode="click"
      >
        <a
          aria-label="RuStore"
          className={cx('icon-wrap')}
          href={ruStore.href}
          rel="noreferrer"
          target="_blank"
        >
          <img alt="" src={ruStore.imageUrl} />
        </a>
      </Metric>
    </div>
  );
};
