import { TUser } from '@beef/utils';
import { debounce, merge } from 'lodash';

import store, { headerStore2024 } from 'store';
import { getAccountType as _getAccountType } from 'utils/analytics/auth';
import { ymPushParams } from 'utils/ym';

import { getUserInfo } from '../../../../store/feature/userInfo/selectors';
import {
  getButtonAnalyticsConverter,
  getInlineButtonAnalyticsConverter,
  getInputAnalyticsConverter,
} from './analytics/converter';
import { Input } from './components/Input';
import { fetchIsBeelineUser } from './components/Input/api/checkCtn';
import { EUserType } from './components/Input/api/constants';
import { EInputErrorTextConstant } from './components/Input/constants';
import {
  InputState,
  getInputTexts,
  onInputChange,
  setError,
  setIsLoading,
} from './components/Input/store';
import { TInputErrorTexts } from './components/Input/types';
import { getIsPhoneNumber } from './components/Input/utils';
import { ETextConstant } from './constants';
import { getTexts as _getTexts, getAnimationData, getContent, getWidgetPayUrl } from './store';
import { store as _store, paymentSlice } from './store/payment';
import { TPaymentWidgetProps, TTexts } from './types';
import {
  DEFAULT_PROPS,
  getPropsFromState,
  mapHeaderUserInfoToUserInfo,
  validateInput,
} from './utils';
import { findFirst } from './utils.common';

const sendAnalytics = debounce((obj: Record<string, unknown>) => {
  if (typeof window === 'undefined') return;

  ymPushParams(obj);
}, 1000);

export const composeRoot = () => {
  const getUserType = (accountNumber: string): EUserType => {
    const isPhoneNumber = getIsPhoneNumber(accountNumber);
    if (isPhoneNumber) return 0;
    return 1;
  };

  const getIsBeelineError = async (accountNumber: string) => {
    const isBeelineUser = await fetchIsBeelineUser({
      accountNumber,
      userType: getUserType(accountNumber),
      baseUrl: getWidgetPayUrl(),
    });

    if (isBeelineUser) return undefined;

    return getInputTexts().beeline || EInputErrorTextConstant.Beeline;
  };

  const getTexts = (): TTexts => {
    const texts = _getTexts();

    return {
      animationData: texts.animationData,
      buttonText: texts.buttonText ?? ETextConstant.ButtonText,
      inlineButtonText: texts.inlineButtonText ?? ETextConstant.InlineButtonText,
      buttonUrl: texts.buttonUrl ?? ETextConstant.ButtonUrl,
      inlineButtonUrl: texts.inlineButtonUrl ?? ETextConstant.InlineButtonUrl,
      inputLabel: texts.inputLabel ?? ETextConstant.InputLabel,
      inputPlaceholder: texts.inputPlaceholder ?? ETextConstant.InputPlaceholder,
      primaryDescription: texts.primaryDescription ?? ETextConstant.PrimaryDescription,
      secondaryDescription: texts.secondaryDescription ?? ETextConstant.SecondaryDescription,
      title: texts.title ?? ETextConstant.Title,
      inputPlaceholderMobile: texts.inputPlaceholderMobile ?? ETextConstant.InputPlaceholderMobile,
    };
  };

  const getInputErrorTexts = (): TInputErrorTexts => {
    const texts = getInputTexts();

    return {
      empty: texts.empty ?? EInputErrorTextConstant.Empty,
      incomplete: texts.incomplete ?? EInputErrorTextConstant.Incomplete,
      beeline: texts.beeline ?? EInputErrorTextConstant.Beeline,
    };
  };

  const getAccountType = (): string => {
    const user = store.getState()?.external?.user as TUser | undefined;

    return _getAccountType(user ?? {});
  };

  const getCommonAnalyticsFields = () => {
    return {
      page_url: window.location.href,
      page_path: window.location.pathname,
      page_title: document.title,
      account_type: getAccountType(),
      block_title: getTexts().title,
      object_type: 'block',
    };
  };

  const analyticsDependencies = {
    sendAnalytics,
    getCommonAnalyticsFields,
    getTexts,
  };

  const buttonAnalyticsConverter = getButtonAnalyticsConverter(analyticsDependencies);
  const inlineButtonAnalyticsConverter = getInlineButtonAnalyticsConverter(analyticsDependencies);
  const inputAnalyticsConverter = getInputAnalyticsConverter(analyticsDependencies);

  const openUrl = (url: string) => {
    if (typeof window === 'undefined') return;
    window.open(url, '_self');
  };

  const getIsLoading = () => !!InputState.getState().isLoading;
  const getIsOpen = () => _store.getState().isOpen;
  const setIsOpen = (isOpen: boolean) => _store.dispatch(paymentSlice.actions.setIsOpen(isOpen));

  const converter = (props: Partial<TPaymentWidgetProps>): TPaymentWidgetProps => {
    const texts = getTexts();

    const validate = async (_value: string): Promise<string | undefined> => {
      const validationError = validateInput(_value, getInputErrorTexts());

      if (validationError) return validationError;

      setIsLoading(true);
      const error = await getIsBeelineError(_value);
      setIsLoading(false);

      if (error) return error;

      return undefined;
    };

    const user = headerStore2024().getState()?.common?.auth?.user;

    const userInfo = findFirst([
      user && mapHeaderUserInfoToUserInfo(user),
      getUserInfo(store.getState()),
    ]);

    const _userInfo = {
      ...userInfo,
      ctn: InputState.getState().value ?? userInfo.ctn,
      type: 0,
      fttb: InputState.getState().value ?? userInfo.fttb,
    };

    return merge({ ...DEFAULT_PROPS }, props, {
      topupBalanceProps: {
        onClose: () => {
          setIsOpen(false);
        },
        userInfo: _userInfo,
        redirectUrl: getContent().redirectUrl ?? ETextConstant.ButtonUrl,
        paymentMethods: getContent().paymentMethods ?? ['fps', 'sberpay', 'card'],
        mobileView: !!props.inputProps?.isMobile,
        isAuth: userInfo.isAuth,
      },
      animationContainerProps: {
        animationData: getAnimationData(),
      },
      textProps: {
        children: texts.title,
      },
      buttonProps: buttonAnalyticsConverter({
        disabled: getIsLoading() || getIsOpen(),
        children: `${texts.buttonText}`,
        onClick: async () => {
          setIsLoading(true);
          const error = await validate(InputState.getState().value ?? '');
          setIsLoading(false);

          if (error) {
            setError(error);
            return;
          }

          setIsOpen(true);
        },
      }),
      isPopupOpen: getIsOpen(),
      inputProps: inputAnalyticsConverter({
        ...DEFAULT_PROPS.inputProps,
        Component: Input,
        placeholder:
          props.inputProps?.isMobile ? texts.inputPlaceholderMobile : texts.inputPlaceholder,
        label: texts.inputLabel,
        value: InputState.getState().value,
        error: InputState.getState().error,
      }),
      text: {
        primary: texts.primaryDescription,
        secondary: texts.secondaryDescription,
      },
      inlineButtonProps: inlineButtonAnalyticsConverter({
        ...DEFAULT_PROPS.inlineButtonProps,
        children: texts.inlineButtonText,
        onClick: () => {
          openUrl(texts.inlineButtonUrl);
        },
      }),
    } as TPaymentWidgetProps);
  };

  const onStateChange = (
    props: Partial<TPaymentWidgetProps>,
    cb: (props: TPaymentWidgetProps) => void,
  ) => {
    return onInputChange((state) => cb(getPropsFromState(props, state)));
  };

  return {
    converter,
    onStateChange,
  };
};
