import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { withRenderFlag } from '@beef/react';
import { Button as _Button, Tag as _Tag, Text as _Text, Banner } from '@carnica/ui-kit';
import type { ButtonProps, TagProps, TextProps } from '@carnica/ui-kit/types';
import { connect } from 'react-redux';

import { ButtonArrow as _ButtonArrow, ButtonLinkType } from 'pages/MainPage/ui/ButtonArrow';
import { Banner as UIBanner } from 'pages/MainPage/ui/Banner';
import { AdaptivityContainer } from 'pages/MainPage/utils/ResponsiveContainers';
import { MenuUrlTarget } from 'pages/MainPage/utils/utils';
import { BreakpointEnum, RootState } from 'pages/MainPage/types';
import { Metric } from 'pages/MainPage/metric';

import { MainCarouselProps } from './types';
import { FullWidthBanner } from '../../ui/Banner/FullWidthBanner';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const Tag: React.FC<TagProps> = withRenderFlag(_Tag);
const Text: React.FC<TextProps> = withRenderFlag(_Text);
const Button: React.FC<ButtonProps> = withRenderFlag(_Button);
const ButtonArrow: React.FC<ButtonLinkType> = withRenderFlag(_ButtonArrow);

// TODO: Надо будет сделать рефакторинг компонента, потому что щас слишком большой
const _MainCarouselBlock: React.FC<MainCarouselProps> = ({
  slides,
  duration = 3000,
  breakpoint,
}) => {
  const [opacity, setOpacity] = useState(1);
  const withControls = breakpoint >= BreakpointEnum.DESKTOP_1025;

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (window.innerWidth > 800 && scrollPosition >= 300) {
        setOpacity(0);
      } else {
        setOpacity(1);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className={cx('carousel')} style={{ opacity }}>
      <Banner
        autoPlay
        className={cx('carousel-swiper')}
        controlsResponseArea={400}
        duration={duration}
        infinite
        loop
        swiperOptions={{
          slideClass: cx('carousel-slide'),
          slidePrevClass: cx('carousel-slide-prev'),
        }}
        withControls={withControls}
      >
        {slides?.map((item, index) => (
          <Metric
            data={JSON.stringify({
              bannerTitle: item.heading?.text,
              bannerDescription: item.subtitle?.text,
              bannerUrl: item.link?.href,
              bannerId: index + 1,
              bannerPosition: `${index + 1}/${slides.length}`,
            })}
            events={{ commonMetric: ['handleTopBannerView'] }}
            mode="viewport"
            once
            threshold={0.2}
          >
            <AdaptivityContainer minWidth={1920}>
              <Metric
                asChild
                data={JSON.stringify({
                  bannerTitle: item.heading?.text,
                  bannerDescription: item.subtitle?.text,
                  bannerUrl: item.link?.href,
                  bannerId: index + 1,
                  bannerPosition: `${index + 1}/${slides.length}`,
                })}
                events={{ commonMetric: ['handleTopBannerClick'] }}
                mode="click"
              >
                <a
                  aria-label={item.heading?.text}
                  href={item.link?.href}
                  target={MenuUrlTarget.get(item.link?.isBlankUrlTarget as boolean)}
                >
                  <FullWidthBanner
                    contentClassName={cx('carousel-slide-content')}
                    image={item.image ? <img alt="" src={item.image?.xl?.url} /> : null}
                    imageStyle={item.image?.xl?.imageStyle}
                    legal={
                      <Text
                        color={item.legal?.color}
                        shouldRender={item.legal}
                        variant="caption-medium"
                      >
                        {item.legal?.text}
                      </Text>
                    }
                    style={{
                      backgroundImage: `url(${item.background?.xl?.url})`,
                      backgroundColor: item.background?.xl?.color,
                      backgroundSize: item.background?.xl?.size,
                      backgroundPosition: item.background?.xl?.position,
                    }}
                  >
                    {item.logo && <img alt="" src={item.logo} />}
                    <Tag
                      className={cx('tag')}
                      shouldRender={item.sticky}
                      size="m"
                      view={item.sticky?.color}
                    >
                      {item.sticky?.text}
                    </Tag>
                    <Text
                      className={cx('heading')}
                      color={item.heading?.color}
                      shouldRender={item.heading}
                      variant="display-large"
                    >
                      {item.heading?.text}
                    </Text>
                    <Text
                      className={cx('subtitle')}
                      color={item.subtitle?.color}
                      shouldRender={item.subtitle}
                      variant="body-large"
                    >
                      {item.subtitle?.text}
                    </Text>
                    <Metric
                      asChild
                      data={JSON.stringify({
                        bannerTitle: item.heading?.text,
                        buttonText: item.actionButton?.label,
                        bannerUrl: item.link?.href,
                        bannerDescription: item.subtitle?.text,
                        bannerId: index + 1,
                        bannerPosition: `${index + 1}/${slides.length}`,
                      })}
                      events={{ commonMetric: ['handleTopBannerButton'] }}
                      mode="click"
                    >
                      <Button
                        className={cx('button')}
                        href={item.actionButton?.href}
                        shouldRender={item.actionButton}
                        size="m"
                        tagName="a"
                        target={MenuUrlTarget.get(item.actionButton?.isBlankUrlTarget as boolean)}
                        view={item.actionButton?.view}
                      >
                        {item.actionButton?.label}
                      </Button>
                    </Metric>
                    <Metric
                      asChild
                      data={JSON.stringify({
                        bannerTitle: item.heading?.text,
                        buttonText: item.actionButtonArrow?.label,
                        bannerUrl: item.link?.href,
                        bannerDescription: item.subtitle?.text,
                        bannerId: index + 1,
                        bannerPosition: `${index + 1}/${slides.length}`,
                      })}
                      events={{ commonMetric: ['handleTopBannerButton'] }}
                      mode="click"
                    >
                      <ButtonArrow
                        className={cx('button')}
                        shouldRender={item.actionButtonArrow}
                        {...item.actionButtonArrow}
                      >
                        <Text
                          color={item.actionButtonArrow?.textColor}
                          shouldRender={item.actionButtonArrow}
                          variant="body-accent-small"
                        >
                          {item.actionButtonArrow?.label}
                        </Text>
                      </ButtonArrow>
                    </Metric>
                  </FullWidthBanner>
                </a>
              </Metric>
            </AdaptivityContainer>
            <AdaptivityContainer maxWidth={1919} minWidth={1440}>
              <Metric
                asChild
                data={JSON.stringify({
                  bannerTitle: item.heading?.text,
                  bannerDescription: item.subtitle?.text,
                  bannerUrl: item.link?.href,
                  bannerId: index + 1,
                  bannerPosition: `${index + 1}/${slides.length}`,
                })}
                events={{ commonMetric: ['handleTopBannerClick'] }}
                mode="click"
              >
                <a
                  aria-label={item.heading?.text}
                  href={item.link?.href}
                  target={MenuUrlTarget.get(item.link?.isBlankUrlTarget as boolean)}
                >
                  <FullWidthBanner
                    contentClassName={cx('carousel-slide-content')}
                    image={item.image ? <img alt="" src={item.image?.l?.url} /> : null}
                    imageStyle={item.image?.l?.imageStyle}
                    legal={
                      <Text
                        color={item.legal?.color}
                        shouldRender={item.legal}
                        variant="caption-medium"
                      >
                        {item.legal?.text}
                      </Text>
                    }
                    style={{
                      backgroundImage: `url(${item.background?.l?.url})`,
                      backgroundColor: item.background?.l?.color,
                      backgroundSize: item.background?.l?.size,
                      backgroundPosition: item.background?.l?.position,
                    }}
                  >
                    {item.logo && <img alt="" src={item.logo} />}
                    <Tag
                      className={cx('tag')}
                      shouldRender={item.sticky}
                      size="m"
                      view={item.sticky?.color}
                    >
                      {item.sticky?.text}
                    </Tag>
                    <Text
                      className={cx('heading')}
                      color={item.heading?.color}
                      shouldRender={item.heading}
                      variant="display-large"
                    >
                      {item.heading?.text}
                    </Text>
                    <Text
                      className={cx('subtitle')}
                      color={item.subtitle?.color}
                      shouldRender={item.subtitle}
                      variant="body-large"
                    >
                      {item.subtitle?.text}
                    </Text>
                    <Metric
                      asChild
                      data={JSON.stringify({
                        bannerTitle: item.heading?.text,
                        buttonText: item.actionButton?.label,
                        bannerUrl: item.link?.href,
                        bannerDescription: item.subtitle?.text,
                        bannerId: index + 1,
                        bannerPosition: `${index + 1}/${slides.length}`,
                      })}
                      events={{ commonMetric: ['handleTopBannerButton'] }}
                      mode="click"
                    >
                      <Button
                        className={cx('button')}
                        href={item.actionButton?.href}
                        shouldRender={item.actionButton}
                        size="m"
                        tagName="a"
                        target={MenuUrlTarget.get(item.actionButton?.isBlankUrlTarget as boolean)}
                        view={item.actionButton?.view}
                      >
                        {item.actionButton?.label}
                      </Button>
                    </Metric>
                    <Metric
                      asChild
                      data={JSON.stringify({
                        bannerTitle: item.heading?.text,
                        buttonText: item.actionButtonArrow?.label,
                        bannerUrl: item.link?.href,
                        bannerId: index + 1,
                        bannerDescription: item.subtitle?.text,
                        bannerPosition: `${index + 1}/${slides.length}`,
                      })}
                      events={{ commonMetric: ['handleTopBannerButton'] }}
                      mode="click"
                    >
                      <ButtonArrow
                        className={cx('button')}
                        shouldRender={item.actionButtonArrow}
                        {...item.actionButtonArrow}
                      >
                        <Text
                          color={item.actionButtonArrow?.textColor}
                          shouldRender={item.actionButtonArrow}
                          variant="body-accent-small"
                        >
                          {item.actionButtonArrow?.label}
                        </Text>
                      </ButtonArrow>
                    </Metric>
                  </FullWidthBanner>
                </a>
              </Metric>
            </AdaptivityContainer>
            <AdaptivityContainer maxWidth={1439} minWidth={1025}>
              <Metric
                asChild
                data={JSON.stringify({
                  bannerTitle: item.heading?.text,
                  bannerDescription: item.subtitle?.text,
                  bannerUrl: item.link?.href,
                  bannerId: index + 1,
                  bannerPosition: `${index + 1}/${slides.length}`,
                })}
                events={{ commonMetric: ['handleTopBannerClick'] }}
                mode="click"
              >
                <a
                  aria-label={item.heading?.text}
                  href={item.link?.href}
                  target={MenuUrlTarget.get(item.link?.isBlankUrlTarget as boolean)}
                >
                  <FullWidthBanner
                    contentClassName={cx('carousel-slide-content')}
                    image={item.image ? <img alt="" src={item.image?.m?.url} /> : null}
                    imageStyle={item.image?.m?.imageStyle}
                    legal={
                      <Text
                        color={item.legal?.color}
                        shouldRender={item.legal}
                        variant="caption-medium"
                      >
                        {item.legal?.text}
                      </Text>
                    }
                    style={{
                      backgroundImage: `url(${item.background?.m?.url})`,
                      backgroundColor: item.background?.m?.color,
                      backgroundSize: item.background?.m?.size,
                      backgroundPosition: item.background?.m?.position,
                    }}
                  >
                    {item.logo && <img alt="" src={item.logo} />}
                    <Tag shouldRender={item.sticky} size="m" view={item.sticky?.color}>
                      {item.sticky?.text}
                    </Tag>
                    <Text
                      color={item.heading?.color}
                      shouldRender={item.heading}
                      variant="display-small"
                    >
                      {item.heading?.text}
                    </Text>
                    <Text
                      color={item.subtitle?.color}
                      shouldRender={item.subtitle}
                      variant="body-small"
                    >
                      {item.subtitle?.text}
                    </Text>
                    <Metric
                      asChild
                      data={JSON.stringify({
                        bannerTitle: item.heading?.text,
                        buttonText: item.actionButton?.label,
                        bannerUrl: item.link?.href,
                        bannerDescription: item.subtitle?.text,
                        bannerId: index + 1,
                        bannerPosition: `${index + 1}/${slides.length}`,
                      })}
                      events={{ commonMetric: ['handleTopBannerButton'] }}
                      mode="click"
                    >
                      <Button
                        href={item.actionButton?.href}
                        shouldRender={item.actionButton}
                        size="m"
                        tagName="a"
                        target={MenuUrlTarget.get(item.actionButton?.isBlankUrlTarget as boolean)}
                        view={item.actionButton?.view}
                      >
                        {item.actionButton?.label}
                      </Button>
                    </Metric>
                    <Metric
                      asChild
                      data={JSON.stringify({
                        bannerTitle: item.heading?.text,
                        buttonText: item.actionButtonArrow?.label,
                        bannerDescription: item.subtitle?.text,
                        bannerUrl: item.link?.href,
                        bannerId: index + 1,
                        bannerPosition: `${index + 1}/${slides.length}`,
                      })}
                      events={{ commonMetric: ['handleTopBannerButton'] }}
                      mode="click"
                    >
                      <ButtonArrow
                        shouldRender={item.actionButtonArrow}
                        {...item.actionButtonArrow}
                      >
                        <Text
                          color={item.actionButtonArrow?.textColor}
                          shouldRender={item.actionButtonArrow}
                          variant="body-accent-small"
                        >
                          {item.actionButtonArrow?.label}
                        </Text>
                      </ButtonArrow>
                    </Metric>
                  </FullWidthBanner>
                </a>
              </Metric>
            </AdaptivityContainer>
            <AdaptivityContainer maxWidth={1024} minWidth={801}>
              <Metric
                asChild
                data={JSON.stringify({
                  bannerTitle: item.heading?.text,
                  bannerDescription: item.subtitle?.text,
                  bannerUrl: item.link?.href,
                  bannerId: index + 1,
                  bannerPosition: `${index + 1}/${slides.length}`,
                })}
                events={{ commonMetric: ['handleTopBannerClick'] }}
                mode="click"
              >
                <a
                  aria-label={item.heading?.text}
                  href={item.link?.href}
                  target={MenuUrlTarget.get(item.link?.isBlankUrlTarget as boolean)}
                >
                  <FullWidthBanner
                    image={item.image ? <img alt="" src={item.image?.s?.url} /> : null}
                    imageStyle={item.image?.s?.imageStyle}
                    legal={
                      <Text
                        color={item.legal?.color}
                        shouldRender={item.legal}
                        variant="caption-medium"
                      >
                        {item.legal?.text}
                      </Text>
                    }
                    style={{
                      backgroundImage: `url(${item.background?.s?.url})`,
                      backgroundColor: item.background?.s?.color,
                      backgroundSize: item.background?.s?.size,
                      backgroundPosition: item.background?.s?.position,
                    }}
                  >
                    {item.logo && <img alt="" src={item.logo} />}
                    <Tag shouldRender={item.sticky} size="m" view={item.sticky?.color}>
                      {item.sticky?.text}
                    </Tag>
                    <Text
                      color={item.heading?.color}
                      shouldRender={item.heading}
                      variant="display-small"
                    >
                      {item.heading?.text}
                    </Text>
                    <Text
                      color={item.subtitle?.color}
                      shouldRender={item.subtitle}
                      variant="body-small"
                    >
                      {item.subtitle?.text}
                    </Text>

                    <Metric
                      asChild
                      data={JSON.stringify({
                        bannerTitle: item.heading?.text,
                        buttonText: item.actionButton?.label,
                        bannerUrl: item.link?.href,
                        bannerDescription: item.subtitle?.text,
                        bannerId: index + 1,
                        bannerPosition: `${index + 1}/${slides.length}`,
                      })}
                      events={{ commonMetric: ['handleTopBannerButton'] }}
                      mode="click"
                    >
                      <Button
                        href={item.actionButton?.href}
                        shouldRender={item.actionButton}
                        size="m"
                        tagName="a"
                        target={MenuUrlTarget.get(item.actionButton?.isBlankUrlTarget as boolean)}
                        view={item.actionButton?.view}
                      >
                        {item.actionButton?.label}
                      </Button>
                    </Metric>
                    <Metric
                      asChild
                      data={JSON.stringify({
                        bannerTitle: item.heading?.text,
                        buttonText: item.actionButtonArrow?.label,
                        bannerDescription: item.subtitle?.text,
                        bannerUrl: item.link?.href,
                        bannerId: index + 1,
                        bannerPosition: `${index + 1}/${slides.length}`,
                      })}
                      events={{ commonMetric: ['handleTopBannerButton'] }}
                      mode="click"
                    >
                      <ButtonArrow
                        shouldRender={item.actionButtonArrow}
                        {...item.actionButtonArrow}
                      >
                        <Text
                          color={item.actionButtonArrow?.textColor}
                          shouldRender={item.actionButtonArrow}
                          variant="body-accent-small"
                        >
                          {item.actionButtonArrow?.label}
                        </Text>
                      </ButtonArrow>
                    </Metric>
                  </FullWidthBanner>
                </a>
              </Metric>
            </AdaptivityContainer>
            <AdaptivityContainer maxWidth={800}>
              <Metric
                asChild
                data={JSON.stringify({
                  bannerTitle: item.heading?.text,
                  bannerDescription: item.subtitle?.text,
                  bannerUrl: item.link?.href,
                  bannerId: index + 1,
                  bannerPosition: `${index + 1}/${slides.length}`,
                })}
                events={{ commonMetric: ['handleTopBannerClick'] }}
                mode="click"
              >
                <a
                  aria-label={item.heading?.text}
                  href={item.link?.href}
                  target={MenuUrlTarget.get(item.link?.isBlankUrlTarget as boolean)}
                >
                  <UIBanner.MobileBanner
                    legal={
                      <Text
                        color={item.legal?.color}
                        shouldRender={item.legal}
                        variant="caption-medium"
                      >
                        {item.legal?.text}
                      </Text>
                    }
                    style={{
                      backgroundImage: `url(${item.background?.xs?.url})`,
                      backgroundColor: item.background?.xs?.color,
                      backgroundSize: item.background?.xs?.size,
                      backgroundPosition: item.background?.xs?.position,
                    }}
                  >
                    {item.logo && <img alt="" src={item.logo} />}
                    <Tag shouldRender={item.sticky} size="m" view={item.sticky?.color}>
                      {item.sticky?.text}
                    </Tag>
                    <Text
                      color={item.heading?.color}
                      shouldRender={item.heading}
                      variant="display-small"
                    >
                      {item.heading?.text}
                    </Text>
                    <Text
                      color={item.subtitle?.color}
                      shouldRender={item.subtitle}
                      variant="body-medium"
                    >
                      {item.subtitle?.text}
                    </Text>
                    <Metric
                      asChild
                      data={JSON.stringify({
                        bannerTitle: item.heading?.text,
                        buttonText: item.actionButton?.label,
                        bannerUrl: item.link?.href,
                        bannerDescription: item.subtitle?.text,
                        bannerId: index + 1,
                        bannerPosition: `${index + 1}/${slides.length}`,
                      })}
                      events={{ commonMetric: ['handleTopBannerButton'] }}
                      mode="click"
                    >
                      <Button
                        href={item.actionButton?.href}
                        shouldRender={item.actionButton}
                        size="m"
                        tagName="a"
                        target={MenuUrlTarget.get(item.actionButton?.isBlankUrlTarget as boolean)}
                        view={item.actionButton?.view}
                      >
                        {item.actionButton?.label}
                      </Button>
                    </Metric>
                    <Metric
                      asChild
                      data={JSON.stringify({
                        bannerTitle: item.heading?.text,
                        buttonText: item.actionButtonArrow?.label,
                        bannerUrl: item.link?.href,
                        bannerDescription: item.subtitle?.text,
                        bannerId: index + 1,
                        bannerPosition: `${index + 1}/${slides.length}`,
                      })}
                      events={{ commonMetric: ['handleTopBannerButton'] }}
                      mode="click"
                    >
                      <ButtonArrow
                        shouldRender={item.actionButtonArrow}
                        {...item.actionButtonArrow}
                      >
                        <Text
                          color={item.actionButtonArrow?.textColor}
                          shouldRender={item.actionButtonArrow}
                          variant="body-accent-small"
                        >
                          {item.actionButtonArrow?.label}
                        </Text>
                      </ButtonArrow>
                    </Metric>
                  </UIBanner.MobileBanner>
                </a>
              </Metric>
            </AdaptivityContainer>
          </Metric>
        ))}
      </Banner>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  breakpoint: state.breakpoint,
});

export const MainCarouselBlock = connect(mapStateToProps)(_MainCarouselBlock);
