import React from 'react';
import { Text } from '@carnica/ui-kit';
import classNames from 'classnames/bind';

import { Android, IOS } from 'pages/CarnicaDSLandings/utils/PlatformContainers';
import { AdaptivityContainer, Mobile } from 'pages/MainPage/utils/ResponsiveContainers';
import { Metric } from 'pages/MainPage/metric';

import { StoresLinks } from '../StoresLinks';
import styles from './styles.pcss';
import { AppContentProps, AppStoresLinks } from '../../types';

const cx = classNames.bind(styles);

export const ContentBlock: React.FC<AppContentProps & AppStoresLinks> = ({
  description,
  title,
  iconUrl,
  links,
  blockTitle,
}) => {
  const { appStore, huaweiStore, ruStore, googleStore } = links;
  return (
    <div className={cx('content')}>
      <img alt="" className={cx('icon')} src={iconUrl} />
      <Text className={cx('title')} size="l">
        {title}
      </Text>
      <Text className={cx('description')} color="secondary" size="xs">
        {description}
      </Text>
      <Mobile>
        <div className={cx('store-links')}>
          {/* TODO: Вообще правило простое - если хотя бы 2-3 раза один и тот класс используется - это надо выносить в отдельный компонентик */}
          <IOS>
            <Metric
              asChild
              data={JSON.stringify({ iconName: 'app store', blockTitle: title })}
              events={{ commonMetric: ['handleIconClick'] }}
              mode="click"
            >
              <a
                aria-label="AppStore"
                className={cx('icon-wrap', 'icon-ios')}
                href={appStore.href}
                rel="noreferrer"
                target="_blank"
              >
                <img alt="" loading="lazy" src={appStore.imageFullUrl} />
              </a>
            </Metric>
          </IOS>
          <Android>
            <Metric
              asChild
              data={JSON.stringify({ iconName: 'google store', blockTitle: title })}
              events={{ commonMetric: ['handleIconClick'] }}
              mode="click"
            >
              <a
                aria-label="Google Play"
                className={cx('icon-wrap')}
                href={googleStore.href}
                rel="noreferrer"
                target="_blank"
              >
                <img alt="" src={googleStore.imageUrl} />
              </a>
            </Metric>
          </Android>
          <Android>
            <Metric
              asChild
              data={JSON.stringify({ iconName: 'huawei store', blockTitle: title })}
              events={{ commonMetric: ['handleIconClick'] }}
              mode="click"
            >
              <a
                aria-label="App Gallery"
                className={cx('icon-wrap')}
                href={huaweiStore.href}
                rel="noreferrer"
                target="_blank"
              >
                <img alt="" src={huaweiStore.imageUrl} />
              </a>
            </Metric>
          </Android>
          <Android>
            <Metric
              asChild
              data={JSON.stringify({ iconName: 'ru store', blockTitle: title })}
              events={{ commonMetric: ['handleIconClick'] }}
              mode="click"
            >
              <a
                aria-label="Ru Store"
                className={cx('icon-wrap')}
                href={ruStore.href}
                rel="noreferrer"
                target="_blank"
              >
                <img alt="" src={ruStore.imageUrl} />
              </a>
            </Metric>
          </Android>
        </div>
      </Mobile>
      <AdaptivityContainer maxWidth={960} minWidth={768}>
        <StoresLinks blockTitle={blockTitle} links={links} />
      </AdaptivityContainer>
    </div>
  );
};
