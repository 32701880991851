import React from 'react';
import { Chips } from '@carnica/ui-kit';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

interface ChipsGroupsProps {
  className?: string;
  items: IChipsItem[];
  onChange: (value: IChipsItem) => void;
  value: IChipsItem;
}

interface IChipsItem {
  key: string;
  label: string;
}

export const ChipsGroups: React.FC<ChipsGroupsProps> = ({ items, onChange, value, className }) => {
  return (
    <div className={cx('group', className)}>
      {items.map((item) => (
        <Chips
          active={item.key === value?.key}
          className={cx('group-item')}
          invert
          key={item.key}
          label={item.label}
          onClick={() => onChange(item)}
        />
      ))}
    </div>
  );
};
