import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { IconArrowsRight } from '@carnica/graphics';
import { withBadge } from '@carnica/ui-kit/hocs';
import { Chips as CarnicaChips } from '@carnica/ui-kit';

import { Metric } from 'pages/MainPage/metric';
import { usePrepareChips } from 'pages/MainPage/blocks/TariffCardBlock/components/Chips/hooks/usePrepareChips';

import styles from './styles.pcss';

const cx = classNames.bind(styles);
const BadgeChips = withBadge(CarnicaChips);

// TODO: в ките что-то с экспортом типов.
// Правильный тип для TChipsProps – ChipsGroupDefaultItem & { badge?: string },
// но ChipsGroupDefaultItem не импортируется, а линтер не пропускает даже с игнором.
export type TChipsProps = any;

/** Компонент-чипса c метрикой, на основе китовых чипсов с бэйджем */
export const Chips: FC<TChipsProps> = ({ label, badge, link, withIcon }) => {
  const { isMobile, metricData } = usePrepareChips(label);

  return (
    <div key={label}>
      <Metric
        asChild
        data={metricData}
        events={{ tariffCardMetric: ['handleChipsClick'] }}
        mode="click"
      >
        <a href={link} rel="noreferrer" target="_blank">
          <BadgeChips
            badgeOptions={badge ? { content: badge } : undefined}
            className={cx('chips')}
            iconRight={!isMobile && withIcon ? IconArrowsRight : undefined}
            invert={isMobile}
            label={label}
          />
        </a>
      </Metric>
    </div>
  );
};
