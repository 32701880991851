import React from 'react';
import { Text } from '@carnica/ui-kit';
import classNames from 'classnames/bind';

import styles from './styles.pcss';
import { AppStoresProps } from '../../types';
import { StoresLinks } from '../StoresLinks';

const cx = classNames.bind(styles);

export const StoresBlock: React.FC<AppStoresProps> = ({
  qrLabel,
  qrImageUrl,
  links,
  blockTitle,
}) => {
  return (
    <div className={cx('store-wrapper')}>
      <div className={cx('store-qr')}>
        <img alt="" className={cx('store-image')} src={qrImageUrl} />
        <Text align="center" className={cx('store-label')} color="tertiary" variant="body-small">
          {qrLabel}
        </Text>
      </div>
      <StoresLinks blockTitle={blockTitle} links={links} />
    </div>
  );
};
