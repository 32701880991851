import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { HeaderRootState } from '@carnica/smart-kit';

import { useIsMobileDimension } from 'utils/hooks/useGetDimensionGroup';

interface UseScrollPositionResult {
  isRendered: boolean;
  transformStyle: string;
  transitionDuration: string;
}

// TODO: Отрефакторить хук
export const useScrollPosition = (
  sectionRef: React.RefObject<HTMLDivElement>,
): UseScrollPositionResult => {
  const [transformStyle, setTransformStyle] = useState('unset');
  const [isRendered, setIsRendered] = useState(false);
  const [transitionDuration, setTransitionDuration] = useState('0.45s');
  const isMobile = useIsMobileDimension();

  const { isBannerHidden } = useSelector(
    (state: { header: HeaderRootState }) => state.header.layout,
  );

  const calculateTransformStyle = useCallback(() => {
    const sectionPosition = sectionRef?.current?.getBoundingClientRect()?.height ?? 0;
    const bannerOffset = isBannerHidden ? 55 : 95;
    const sectionPos =
      window.scrollY > 0 ? 0 : Math.max(0, window.innerHeight - sectionPosition - bannerOffset);

    return `translate(-50%, -${sectionPos}px)`;
  }, [sectionRef, isBannerHidden]);

  useEffect(() => {
    if (!isMobile && sectionRef?.current && !isRendered) {
      const transformTimer = setTimeout(() => {
        setTransformStyle(calculateTransformStyle());
      }, 50);

      const renderTimer = setTimeout(() => {
        setIsRendered(true);
      }, 100);

      return () => {
        clearTimeout(transformTimer);
        clearTimeout(renderTimer);
      };
    }

    return undefined;
  }, [isMobile, isRendered, sectionRef]);

  useEffect(() => {
    if (isMobile) return undefined;

    const handleScroll = () => setTransformStyle(calculateTransformStyle());

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isBannerHidden]);

  const calculateTransitionDuration = useCallback(() => {
    if (window.innerHeight >= 1000) {
      return '0.8s';
    }

    if (window.innerHeight >= 768 && window.innerHeight < 1000) {
      return '0.65s';
    }
    return '0.45s';
  }, []);

  useEffect(() => {
    setTransitionDuration(calculateTransitionDuration());

    const handleResize = () => {
      setTransitionDuration(calculateTransitionDuration());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (isMobile) return undefined;

    setTransformStyle(calculateTransformStyle());

    return () => undefined;
  }, [isBannerHidden]);

  return { transformStyle, isRendered, transitionDuration };
};
