import { UserInfo, UserType } from '@beep/topup';

import { TInputState } from './components/Input/types';
import { TPaymentWidgetProps } from './types';
import { findFirst } from './utils.common';

export const DEFAULT_PROPS: TPaymentWidgetProps = {
  topupBalanceProps: {
    mobileView: false,
    onClose: () => {},
    paymentMethods: [],
    userInfo: {},
  },
  animationContainerProps: {
    animationData: '',
  },
  buttonProps: {
    children: '',
    disabled: true,
    onClick: () => {},
  },
  inlineButtonProps: {
    children: '',
    disabled: false,
    onClick: () => {},
  },
  inputProps: {
    label: '',
    onBlur: () => Promise.resolve(),
    onChange: () => {},
    onClick: () => {},
    placeholder: '',
    value: undefined,
  },
  text: {
    primary: '',
    secondary: '',
  },
  textProps: {
    children: '',
  },
};

export const mapHeaderUserInfoToUserInfo = ({
  isMobile,
  isConvergent,
  ctn,
  fttbNumber,
}: Partial<{
  accountText: string;
  ctn: string;
  fttbNumber: string;
  isConvergent: boolean;
  isMobile: boolean;
  isXbr: boolean;
  login: string;
}>): UserInfo => ({
  type: findFirst([(isMobile || isConvergent) && UserType.Mobile]) ?? UserType.Fttb,
  ctn,
  fttb: fttbNumber,
});

export const getPropsFromState = (
  props: Partial<TPaymentWidgetProps>,
  state: TInputState,
): TPaymentWidgetProps => {
  return {
    ...DEFAULT_PROPS,
    ...props,
    buttonProps: {
      ...DEFAULT_PROPS.buttonProps,
      ...props.buttonProps,
      disabled: !!state.isLoading,
    },
    inputProps: {
      ...DEFAULT_PROPS.inputProps,
      ...props.inputProps,
      disabled: !!state.isDisabled,
      value: state.value,
      error: state.error,
    },
  };
};

export const validateInput = (
  value: string | undefined,
  texts: { empty: string; incomplete: string } = {
    empty: 'не может быть пустым',
    incomplete: 'введите полный номер', // FIXME: Constants
  },
): string | undefined => {
  if (!value) return texts.empty;
  if (value.length < 10) return texts.incomplete;
  return undefined;
};
