import React from 'react';
import classNames from 'classnames/bind';
import { Text } from '@carnica/ui-kit';

import { ButtonArrow } from 'pages/MainPage/ui';
import { Metric } from 'pages/MainPage/metric';
import { commonMetric } from 'pages/MainPage/metric/common';

import { YourServiceBlockProps } from './types';
import { Content } from './components/Content';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const YourServiceBlock: React.FC<YourServiceBlockProps> = ({
  title,
  btnLink,
  btnLabel,
  cards,
}) => {
  return (
    <Metric
      asChild
      data={title}
      events={{ commonMetric: ['handleBlockView'] }}
      mode="viewport"
      once
      threshold={0.2}
    >
      <div className={cx('block')}>
        <Text className={cx('heading-title')} color="primary" variant="headline-medium">
          {title}
        </Text>
        <Content
          cards={cards}
          onCardClick={(cardTitle) =>
            commonMetric.handleCardClick(
              JSON.stringify({
                blockTitle: title,
                elementTitle: cardTitle,
              }),
            )
          }
        />
        {btnLabel && (
          <ButtonArrow
            arrowFill="secondary"
            className={cx('actions')}
            href={btnLink}
            labelColor="secondary"
          >
            <Text color="secondary" variant="body-small">
              {btnLabel}
            </Text>
          </ButtonArrow>
        )}
      </div>
    </Metric>
  );
};
