import React from 'react';
import classNames from 'classnames/bind';

import { BreakpointEnum } from 'pages/MainPage/types';

import styles from './styles.pcss';
import { Container } from '../Container';
import { connector } from './connector';

const cx = classNames.bind(styles);

interface ITransitionContainer {
  breakpoint: BreakpointEnum;
  children: React.ReactNode;
}

export const _TransitionContainer: React.FC<ITransitionContainer> = ({ children, breakpoint }) => {
  const isMobile = breakpoint < BreakpointEnum.TABLET_481;
  return (
    <div className={cx('wrapper')}>
      <Container
        style={{
          transform: `translateY(${isMobile ? 0 : '-40px'})`,
        }}
      >
        {children}
      </Container>
    </div>
  );
};

export const TransitionContainer = connector(_TransitionContainer);
