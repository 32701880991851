import React, { useEffect, useRef, useState } from 'react';
import { compose, withReduxStore } from '@beef/redux';
import { withDataConverter } from '@beef/react';
import { EHeaderTheme, useGetHeaderHeight } from '@carnica/smart-kit';

import { TransitionContainer } from 'pages/MainPage/layout/TransitionContainer';
import { mainPageConvertor } from 'pages/MainPage/adapters';
import { withAdaptivity } from 'pages/MainPage/utils/useAdaptiveBreakpoints/withAdaptivity';
import { breakpoints } from 'pages/MainPage/utils/constants';
import { setBreakpoint } from 'pages/MainPage/store/actions';
import { useHeaderTheme } from 'pages-components/HeaderV2/hooks';
import { TabBarBlock } from 'pages/MainPage/blocks/TabBarBlock';
import { usePrepareSectionBlocks } from 'pages/MainPage/utils/usePrepareSectionBlocks';
import { withMetricContainer } from 'pages/MainPage/metric';
import { commonMetric } from 'pages/MainPage/metric/common';

import {
  AppSection,
  ForBusiness,
  ForDevices,
  ForFamily,
  ForHome,
  ForYou,
  HRSection,
  HelpSection,
  MainCarouselSection,
  ServiceSection,
} from './sections';
import { initializeStore } from './store';
import { AppLayout } from './layout/AppLayout';
import { MainPageProps, SectionType } from './types';

const SECTION_COMPONENTS: Record<string, React.ComponentType<any>> = {
  [SectionType.AppSection]: AppSection,
  [SectionType.ForBusiness]: ForBusiness,
  [SectionType.ForDevices]: ForDevices,
  [SectionType.ForFamily]: ForFamily,
  [SectionType.ForHome]: ForHome,
  [SectionType.ForYou]: ForYou,
  [SectionType.HRSection]: HRSection,
  [SectionType.ServiceSection]: ServiceSection,
  [SectionType.HelpSection]: HelpSection,
};

const _MainPage: React.FC<MainPageProps> = ({ sectionsFiltered }) => {
  const sectionsFetched = usePrepareSectionBlocks();
  useHeaderTheme(EHeaderTheme.primary);

  // TODO: Брать из carnica когда там уберут debounce
  const height = useGetHeaderHeight();

  const sectionRef = useRef(null);
  const [rendered, setRendered] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      commonMetric.handleMainPageView();
      setRendered(true);
    }, 100);
  }, []);

  return (
    <AppLayout style={{ paddingTop: height }}>
      <MainCarouselSection innerRef={sectionRef} />
      {rendered && <TabBarBlock sectionRef={sectionRef} />}
      <TransitionContainer>
        {sectionsFiltered.map(({ section }) => {
          const SectionComponent = SECTION_COMPONENTS[section];
          if (!SectionComponent) {
            return null;
          }

          return <SectionComponent key={section} />;
        })}

        {sectionsFetched?.map(({ section }) => {
          const SectionComponent = SECTION_COMPONENTS[section];
          if (!SectionComponent) {
            return null;
          }

          return <SectionComponent key={section} />;
        })}
      </TransitionContainer>
    </AppLayout>
  );
};

export const MainPage = compose(
  withDataConverter(mainPageConvertor),
  withReduxStore(initializeStore, {
    passThroughProps: ({ sectionsFiltered }: MainPageProps) => {
      return { sectionsFiltered };
    },
  }),
  withAdaptivity({ breakpoints, setBreakpoint }),
  withMetricContainer,
)(_MainPage);
