import React from 'react';
import classNames from 'classnames/bind';
import { Button, Text } from '@carnica/ui-kit';

import { Metric } from 'pages/MainPage/metric';
import { MenuUrlTarget } from 'pages/MainPage/utils/utils';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export interface MnpContentProps {
  blockTitle: string;
  buttonText: string;
  desktopIcon: string;
  href: string;
  isBlankUrlTarget: boolean;
  isMobile: boolean;
  mobileIcon: string;
  subtitle: string;
  title: string;
}

export const MnpContent: React.FC<MnpContentProps> = ({
  title,
  subtitle,
  buttonText,
  mobileIcon,
  desktopIcon,
  href,
  isBlankUrlTarget,
  isMobile,
  blockTitle,
}) => {
  return (
    <>
      <div className={cx('content')}>
        <Text className={cx('title')} variant="display-small">
          {title}
        </Text>
        <Text className={cx('subtitle')} color="secondary" variant="body-small">
          {subtitle}
        </Text>
        <Metric
          asChild
          data={JSON.stringify({ blockTitle, buttonText })}
          events={{ commonMetric: ['handleButtonClick'] }}
          mode="click"
        >
          <Button
            className={cx('button')}
            href={href}
            tagName="a"
            target={MenuUrlTarget.get(isBlankUrlTarget)}
            width={isMobile ? 'full' : 'default'}
          >
            {buttonText}
          </Button>
        </Metric>
        {isMobile && <img alt="" className={cx('icon')} src={mobileIcon} />}
      </div>
      {!isMobile && <img alt="" className={cx('icon')} src={desktopIcon} />}
    </>
  );
};
